import { graphql, useStaticQuery } from 'gatsby';

import Layout from 'layouts/default';

import AllTheDevices from 'components/content-blocks/all-the-devices';
import CustomerReviews from 'components/content-blocks/customer-reviews/customer-reviews';
import CustomerStories from 'components/content-blocks/customer-stories';
import FeaturesGrid from 'components/content-blocks/features-grid';
import HighlightCards from 'components/content-blocks/highlight';
import MediaObject from 'components/content-blocks/media-object';
import MobileFeatures from 'components/content-blocks/mobile-features';
import IntegrationsInfo from 'components/globals/integrations-info';
import Integrations from 'components/globals/payroll-integrations';
import Yoast from 'components/globals/yoast';
import { HeroEnterprise } from 'components/hero/enterprise';

import './styles/enterprise.scss';

export default function Enterprise() {
  const data = useStaticQuery(graphql`
    {
      allWordpressPage(filter: { slug: { eq: "enterprise" } }) {
        edges {
          node {
            wordpress_id
        yoast_head_json {
          title
          description
          canonical
          og_title
          og_description
          og_image {
            url {
              source_url
            }
          }
          og_url
          og_type
          twitter_title
          twitter_description
          twitter_image {
            source_url
          }
        }
            acf {
              hero {
                title
                sub_title
                copy
                schedule_demo {
                  button_text
                }
                background_desktop_image {
                  source_url
                }
                background_mobile_image {
                  source_url
                }
                customer_info {
                  name
                  business
                  date
                }
              }
              media_object {
                body
                sub_title
                title
                desktop_image {
                  alt_text
                  source_url
                }
                mobile_image {
                  alt_text
                  source_url
                }
                button_url
                button_text
              }
              features_grid {
                title
                sub_title
                buttons {
                  title
                  body
                  alt_text
                  icon_width
                  icon
                  mobile_screenshot {
                    source_url
                  }
                  desktop_screenshot {
                    source_url
                  }
                }
              }
              cards_1 {
                header {
                  title
                  sub_title
                }
                card {
                  header
                  image {
                    source_url
                  }
                  title
                  copy
                  link_url
                  link_text
                }
              }
              cards_2 {
                header {
                  title
                  sub_title
                }
                card {
                  header
                  image {
                    source_url
                  }
                  title
                  copy
                  link_url
                  link_text
                }
              }
              customer_stories {
                card_groups {
                  card {
                    card_link
                    card_link_title
                    sub_title
                    title
                    image {
                      alt_text
                      source_url
                    }
                  }
                }
                card_section_title
                section {
                  copy
                  sub_title
                  title
                }
              }
              footer_signup {
                header
                button_text
              }
              payroll_integrations {
                background_image_desktop {
                  source_url
                }
                background_image_mobile {
                  source_url
                }
                title
                sub_title
                copy
                logos {
                  logo {
                    source_url
                    alt_text
                  }
                }
                logos_combined {
                  source_url
                  alt_text
                }
              }
              features_grid_mobile_1 {
                title
                sub_title
                buttons {
                  button_1 {
                    icon_from_library
                    icon_width
                    title
                    body
                    desktop_screenshot {
                      source_url
                    }
                    mobile_screenshot {
                      source_url
                    }
                  }
                  button_2 {
                    icon_from_library
                    icon_width
                    title
                    body
                    desktop_screenshot {
                      source_url
                    }
                    mobile_screenshot {
                      source_url
                    }
                  }
                  button_3 {
                    icon_from_library
                    icon_width
                    title
                    body
                    desktop_screenshot {
                      source_url
                    }
                    mobile_screenshot {
                      source_url
                    }
                  }
                  button_4 {
                    icon_from_library
                    icon_width
                    title
                    body
                    desktop_screenshot {
                      source_url
                    }
                    mobile_screenshot {
                      source_url
                    }
                  }
                }
              }
            }
          }
        }
      }
      wordpressAcfOptions {
        options {
          integrations_info {
            title
            sub_title
            copy
            computer_image_desktop {
              source_url
            }
            computer_image_mobile {
              source_url
            }
            curve_image {
              source_url
            }
          }
          customer_reviews {
            title
            sub_title
            customer {
              name
              company
              quote
              image {
                source_url
              }
            }
          }
        }
      }
    }
  `);

  const pageContext = data.allWordpressPage.edges[0].node.acf;
  const hero = pageContext.hero;
  const mediaObject = pageContext.media_object;
  const integrationsInfo = data.wordpressAcfOptions.options.integrations_info;
  const integrations = pageContext.payroll_integrations;
  const customerStories = pageContext.customer_stories;
  const featuresGrid = pageContext.features_grid;
  const featuresGridMobile = pageContext.features_grid_mobile_1;
  const highlightCards1 = pageContext.cards_1;
  const highlightCards2 = pageContext.cards_2;
  const customerReviews = data.wordpressAcfOptions.options.customer_reviews;

  return (
    <Layout
      showScheduleDemoButton
      showInitFields={ false }
      footerSignupHeader={ pageContext.footer_signup.header }
      footerSignupButtonText={ pageContext.footer_signup.button_text }
    >
      <div className="enterprise-page-container" data-signal={ data.allWordpressPage.edges[0].node.wordpress_id }>
        <Yoast { ...data.allWordpressPage.edges[0].node.yoast_head_json } />
        <HeroEnterprise
          { ...hero }
          customerInfo
          scheduleDemoClassName="hero button is-primary is-rounded intercom-launch-demo"
          logosDesktop="https://marketing-assets.wheniwork-production.com/2020/05/21143913/logos.png"
          logosMobile="https://marketing-assets.wheniwork-production.com/2020/05/21155240/logos-mobile.png"
        />
        <HighlightCards
          { ...highlightCards1 }
          className="highlight-basic"
          hasTitle
          hasImage
        />
        <AllTheDevices />
        <Integrations { ...integrations } logos={ undefined } />
        <MediaObject { ...mediaObject[0] } />
        <IntegrationsInfo { ...integrationsInfo } />
        <MobileFeatures { ...featuresGridMobile } />
        <FeaturesGrid { ...featuresGrid[0] } />
        <FeaturesGrid { ...featuresGrid[1] } className="reverse" />
        <FeaturesGrid { ...featuresGrid[2] } />
        <HighlightCards
          { ...highlightCards2 }
          hasTitle
          hasCopy
          className="highlight-stats"
        />

        <CustomerStories { ...customerStories } hasNoIndustryTitles />
        <CustomerReviews { ...customerReviews } hasImage hasAwards />
        <MediaObject { ...mediaObject[2] } className="flexible" />
      </div>
    </Layout>
  );
}
